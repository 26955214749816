import algosdk from 'algosdk';
import { peraWallet } from "../components/PeraWalletButton";
import { congrats } from './store';

export async function signTrx(wallet, claimPhrase) {
    try {
        const headers = new Headers()
        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')

        const res = await fetch('https://f707pjoc9g.execute-api.eu-west-1.amazonaws.com/api/asa', {
            method: 'POST',
            body: JSON.stringify({ 'claim-phrase': claimPhrase }),
            headers,
            mode: 'cors'
        })
        const body = await res.json()
        const algodToken = '';
        const algodServer = 'https://mainnet-api.4160.nodely.dev';
        const algodPort = 443;
        const algodClient = new algosdk.Algodv2(algodToken, algodServer, algodPort);
        const suggestedParams = await algodClient.getTransactionParams().do();
        const createAssetTransaction = algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject({
          from: wallet,
          suggestedParams,
          defaultFrozen: false,
          unitName: body['unit-name'],
          assetName: body['asset-name'],
          manager: wallet,
          reserve: wallet,
          freeze: wallet,
          clawback: wallet,
          assetURL: body['metadata-url'],
          total: 1,
          decimals: 0,
        });
        
        const trx = [{txn: createAssetTransaction, signers: [wallet]}]

        let [signed_optin] = await peraWallet.signTransaction([ trx ]);
        const strx = Buffer.from(signed_optin).toString("base64");
        await fetch('https://f707pjoc9g.execute-api.eu-west-1.amazonaws.com/api/claim', {
            method: 'POST',
            body: JSON.stringify({ 'encoded-transaction': strx, address: wallet }),
            headers,
            mode: 'cors'
        })
        congrats.set(true)
      } catch (error) {
        console.error("Couldn't sign Create asset txns", error);
      }
}