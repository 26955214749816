import { useEffect } from "react";
import { PeraWalletConnect } from "@perawallet/connect";
import { useStore } from '@nanostores/react'
import { accountAddress, claimString } from "../utils/store";
import { signTrx } from "../utils/web3";

export const peraWallet = new PeraWalletConnect()

export default function PeraWalletButton() {
  const address = useStore(accountAddress);
  const claim = useStore(claimString);
  
    useEffect(() => {
      // Reconnect to the session when the component is mounted
      peraWallet.reconnectSession().then((accounts) => {
        // Setup the disconnect event listener
        peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);
  
        if (accounts.length) {
          accountAddress.set(accounts[0]);
          signTrx(accounts[0], claim)
        }
      });
    }, []);

    function handleConnectWalletClick() {
      peraWallet
        .connect()
        .then((newAccounts) => {
          // Setup the disconnect event listener
          peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);
          console.log({newAccounts})
          accountAddress.set(newAccounts[0]);
          signTrx(newAccounts[0], claim)
          // wallet.set(newAccounts[0]);
        })
        .catch((error) => {
          // You MUST handle the reject because once the user closes the modal, peraWallet.connect() promise will be rejected.
          // For the async/await syntax you MUST use try/catch
          console.error(error)
          if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
            // log the necessary errors
          }
        });
    }
  
    function handleDisconnectWalletClick() {
      peraWallet.disconnect();
      accountAddress.set(null);
      // wallet.set(null);
    }
  
    return (
      <button
        onClick={
          !!address ? handleDisconnectWalletClick : handleConnectWalletClick
        }>
        {!!address ? "DISCONNETTI WALLET" : "RISCUOTI IL TUO NFT"}
      </button>
    );
}
